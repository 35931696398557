import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Flex } from "@src/components/Boxes";
import { GQLImage } from "@src/components/GQLImage";
import React, { ReactNode } from "react";

const VARIANTS = (theme: Theme) =>
  ({
    success: css`
      border-radius: 10px;
      padding: 24px;
      background-color: ${theme.__new.colors.backgroundSuccess};
      img {
        width: 70px;
        height: 70px;
        align-self: flex-start;
      }
    `,
    warning: css`
      border-radius: 10px;
      padding: 24px;
      background-color: ${theme.__new.colors.backgroundDanger};
      img {
        width: 70px;
        height: 70px;
        align-self: flex-start;
      }
    `,
  } as const);

type Variant = "success" | "warning";

interface MentorAlertProps {
  variant: Variant;
  title?: string;
  body: ReactNode;
  icon?: GQLImage;
}

const MentorAlertTitle = styled.div`
  color: #2b2b2b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DojoText";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
`;

export const MentorAlertBody = styled.div`
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DojoText";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  a {
    color: #7d40ff;
  }
`;

const MentorAlert = ({ variant, title, body, icon }: MentorAlertProps) => {
  return (
    <Flex css={(theme) => VARIANTS(theme)[variant]} flexDirection="row" gap={24}>
      {icon && <img src={icon.publicURL} alt="icon" />}
      <Flex flexDirection="column">
        {title && <MentorAlertTitle>{title}</MentorAlertTitle>}
        {body}
      </Flex>
    </Flex>
  );
};

export default MentorAlert;
