import React, { useEffect } from "react";
import { Wizard } from "react-use-wizard";
import { InputStepTemplate } from "./InputStepTemplate";
import { FirstStep } from "./FirstStep";
import { CSSProperties } from "@emotion/serialize";
import { useGetStepsData } from "../hooks/useGetStepsData";
import { useGetEligibilityMessage } from "../hooks/useGetEligibilityMessage";
import { useTrackEventLoad } from "../hooks/useTrackEventLoad";
import { usePage } from "../hooks/usePage";
import { useLoadPersistedForm } from "./MentorApplicationForm";
import Eligibility from "./Eligibility";

const maxAllowedBirthDate = new Date(new Date().setFullYear(new Date().getFullYear() - 13));
export const maxAllowedBirthDateString = `${maxAllowedBirthDate.getFullYear()}-${maxAllowedBirthDate
  .getUTCMonth()
  .toString()
  .padStart(2, "0")}-${maxAllowedBirthDate.getUTCDay().toString().padStart(2, "0")}`;

export type ImageCss = CSSProperties;

export interface ValidationError {
  message: string;
}

export const MentorApplicationSteps = () => {
  useTrackEventLoad("form_view_load");
  const persisted = useLoadPersistedForm();
  const { trackEvent } = usePage();
  const eligible = useGetEligibilityMessage();
  const { steps } = useGetStepsData();
  const showStart = eligible?.isEligible ?? true;

  useEffect(() => {
    if (eligible && eligible.isEligible === false) {
      trackEvent("steps.eligibility_check_non_eligible", { eventValue: eligible.reason });
    }
  }, [eligible, trackEvent]);

  if (!eligible) return null;

  if (!eligible.isEligible) return <Eligibility eligible={eligible} />;

  return (
    <Wizard startIndex={persisted.activeStep}>
      <FirstStep showStart={showStart} />
      <InputStepTemplate step={steps[0]} />
      <InputStepTemplate step={steps[1]} />
      <InputStepTemplate step={steps[2]} />
      <InputStepTemplate step={steps[3]} />
      <InputStepTemplate step={steps[4]} />
      <InputStepTemplate step={steps[5]} />
      <InputStepTemplate step={steps[6]} isSubmit />
    </Wizard>
  );
};
